<template>
  <BackgroundLinesComponent class="BackgroundLines" />
  <BackgroundParticlesComponent class="BackgroundLines" />

  <div class="flex flex-col h-full max-w-screen-2xl mx-auto">
    <HeaderComponent class="flex-none my-4" />

    <div class="mx-auto px-4 py-8 grow grid">
        <ShiningCardComponent>
          <h2 class="card-title justify-center">Under Construction 👷‍♀️</h2>
        </ShiningCardComponent>
        <MusicPlayerCard />
    </div>

    <FooterComponent class="flex-none" />
  </div>
</template>

<script>
  import FooterComponent from './components/layout/FooterComponent.vue'
  // import CardsHolderComponent from './components/layout/CardsHolderComponent.vue'
  import HeaderComponent from './components/layout/HeaderComponent.vue'
  import BackgroundLinesComponent from './components/backgrounds/BackgroundLinesComponent.vue'
  import BackgroundParticlesComponent from './components/backgrounds/BackgroundParticlesComponent.vue'
  
  import ShiningCardComponent from './components/layout/ShiningCardComponent.vue'
  import MusicPlayerCard from './components/cards/MusicPlayerCard.vue'

  export default {
    name: 'App',
    components: {
      FooterComponent,
      HeaderComponent,
      BackgroundLinesComponent,
      BackgroundParticlesComponent,
      ShiningCardComponent,
      MusicPlayerCard
    }
  }

  // BG: https://codepen.io/osorina/pen/PQdMOO
  // Effect shine: https://codepen.io/liyrofx/pen/poVZeEG
</script>

<style>
  #app,
  html,
  body {
    height: 100%;
  }

  .BackgroundLines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
  }
</style>