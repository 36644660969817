<template>
  <div class="container-parent">
    <div class="container">
      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BackgroundLinesComponent',
  }
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }

  .container {
    display: table;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #000;
    line-height: 1.6;
    position: relative;
    font-family: sans-serif;
    overflow: hidden;
  }

  .lines {
    position: absolute;
    display: flex;
    justify-content: space-around;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 100%;
    flex-direction: row;
  }

  .line {
    width: 1px;
    max-width: 1px;
    height: 100%;
    background: rgba(var(--nc), 0.5);
    overflow: hidden;
  }

  .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 1px;
    top: -50%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, oklch(var(--bc)) 75%, oklch(var(--bc)) 100%);
    -webkit-animation: drop 7s 0s infinite;
    animation: drop 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  }

  .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }

  .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
  }

  @-webkit-keyframes drop {
    0% {
      top: -50%;
    }

    100% {
      top: 110%;
    }
  }

  @keyframes drop {
    0% {
      top: -50%;
    }

    100% {
      top: 110%;
    }
  }
</style>