<template>
    <div class="relative w-96">
        <!-- START Box -->
        <div class="animated-border-box-glow"></div>
        <div class="animated-border-box text-centre p-4 align-center">
            <slot></slot>
        </div>
        <!-- END -->
    </div>
</template>

<script>
    export default {
        name: 'ShiningCardComponent',
        components: {
        },
        data () {
            return {
                interval: null
            }
        },
        mounted() {
            this.interval = setInterval(() => {
                // Scale the glow to the resolves size of the card
                const card = document.querySelector('.animated-border-box');
                const glow = document.querySelector('.animated-border-box-glow');

                // Get the size of card
                const cardWidth = card.offsetWidth;
                const cardHeight = card.offsetHeight;

                // Set the size of the glow
                glow.style.width = `${cardWidth}px`;
                glow.style.height = `${cardHeight}px`;                
            }, 100);
        },
        beforeUnmount() {
            clearInterval(this.interval);
        }
    }
</script>

<style scoped>
/*// Glow Border Animation //*/

.animated-border-box, .animated-border-box-glow{
  position: relative;
  overflow: hidden; 
  z-index: 0;
  /* Border Radius */
	border-radius: 20px;
}

.animated-border-box-glow{
    position: absolute;
  overflow: hidden;
  /* Glow Blur */
  filter: blur(20px);
  border-radius: 0px;
  padding: 16px;
}

.animated-border-box:before, .animated-border-box-glow:before {
  content: '';
  z-index: -2;
  text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
	width: 99999px;
	height: 99999px;
	background-repeat: no-repeat;
	background-position: 0 0;
  /*border color, change middle color*/
	background-image: conic-gradient(rgba(0,0,0,0), #19ed7f, rgba(0,0,0,0) 25%);
  /* change speed here */
	animation: rotate 4s linear infinite;
}

.animated-border-box:after {
	content: '';
	position: absolute;
	z-index: -1;
  /* border width */
	left: 5px;
	top: 5px;
  /* double the px from the border width left */
	width: calc(100% - 10px);
	height: calc(100% - 10px);
  /*bg color*/
	background: oklch(var(--b2));
  /*box border radius*/
	border-radius: 15px;
}

@keyframes rotate {
	100% {
		transform: translate(-50%, -50%) rotate(1turn);
	}
}
</style>