<template>
    <div>
        <div class="container-parent">
        </div>
    </div>
  </template>
  
  <script>
    import Sparticles from "sparticles";

    export default {
      name: 'BackgroundParticlesComponent',
      data: () => ({
        options: {
            colors: ["#ff6f61", "#ffcc00"],
            shape: "line",
            speed: 0,
            glow: 10,
            twinkle: false,
            minSize: 50,
            maxSize: 70,
            rotation: 0.1,
            alphaSpeed: 2,
            alphaVariance: 10,
            count: 30
        },
        sparticles: null
      }),
      mounted() {
        console.log(this);
        let node = this.$el;
        this.sparticles = new Sparticles(node, this.options);
      }
    }
  </script>

  <style scoped>
    .container-parent {
      position: relative;
      z-index: -1;
    }
    .container-parent canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    </style>