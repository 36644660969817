<template>


  <footer class="flex flex-row content-center justify-center rounded-lg m-4 p-1 py-3 align-baseline">
    <a v-for="(item, index) in links" v-bind:key="index" class="flex flex-col mx-5" :href="item.href">
      <FontAwesomeIcon :icon="item.icon" />
      <p class="text-sm">{{ item.text }}</p>
    </a>
  </footer>

</template>

<script setup>
  import { faGithub, faXTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons'
</script>

<script>
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

  export default {
    name: 'FooterComponent',
    components: {
      FontAwesomeIcon
    },
    data() {
      return {
        links: [
          { icon: faGithub, text: 'Github', href: 'https://github.com/BruceKristelijn/' },
          { icon: faXTwitter, text: 'X.com', href: 'https://x.com/FourtyFourr_' },
          { icon: faDiscord, text: 'Discord', href: '' },
        ],
        loaded: false
      }
    },
    mounted() {
      this.loaded = true
    }
  }
</script>

<style scoped>
  .animated-component.fade-enter-from,
  .animated-component.zoom-enter-from {
    transition: none;
  }

  /* Fade animation */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 300ms ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  /* Zoom animation */
  .zoom-enter-active,
  .zoom-leave-active {
    transition: transform 300ms ease;
  }

  .zoom-enter-from,
  .zoom-leave-to {
    transform: scale(0.9);
  }
</style>