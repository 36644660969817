<template>
  <div class="card bg-base-100 image-full w-96 shadow-xl">
    <div id="video-player-container">
      <div id="video-player"></div>
    </div>
    <figure>
      <img :src="imageUrl" alt="Seaside Cafe Ambience" class="w-full h-48 object-cover relative" @click="playpause">
    </figure>

    <div class="card-body text-neutral-content">
      <p class="text-center text-white text-xs my-0 pt-0">Music mix I currently enjoy:</p>
      <h2 class="card-title text-primary justify-center">{{videoName}}</h2>
      
        <button class="btn align-center mt-8 mb-2 mx-auto" @click="playpause">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="margin-left: 3px;" 
            v-if="!isPLaying"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
              d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
            v-if="isPLaying"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
              d="M48 64C21.5 64 0 85.5 0 112L0 400c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-288c0-26.5-21.5-48-48-48L48 64zm192 0c-26.5 0-48 21.5-48 48l0 288c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-288c0-26.5-21.5-48-48-48l-32 0z" />
          </svg>
        </button>
        <progress class="progress w-full" :value="progress" :max="length"></progress>

      <div>
        <!-- Music bars -->
      </div>
      <div class="particles">
        <!-- Floating dust particles -->
         <div class="particle"></div>
         <div class="particle"></div>
         <div class="particle"></div>
      </div>
    </div>
  </div>
</template>

<script>
  // ------------------------------------------------------------
  // [Currenty unused] A pretty card to display a music player with a mix I like at the moment
  // ------------------------------------------------------------

  import YouTubePlayer from 'youtube-player';

  export default {
    name: 'MusicPlayerCard',
    components: {
    },
    data() {
      return {
        videoId: '_qoh9g29ib8',
        videoName: 'Seaside Cafe Ambience',
        color1: '#f3e7e9',
        color2: '#f3e7e9',
        player: null,
        isPLaying: false,
        length: 600,
        progress: 10,
        checkProgressInterval: null
      }
    },
    methods: {
      playpause() {
        console.log(this.player);
        this.player.getCurrentTime().then(o => console.log(o));
        this.player.getPlayerState().then((state) => {
          if (state === 1) {
            this.player.pauseVideo();
            this.isPLaying = false;
          } else {
            this.player.playVideo();
            this.isPLaying = true;
          }
                  });
      }
    },
    created(){
      this.imageUrl = `https://i.ytimg.com/vi/` + this.videoId + `/maxresdefault.jpg`;
    },
    mounted() {
      this.player = YouTubePlayer('video-player', { videoId: this.videoId });
      this.player.getDuration().then((l) => {
        console.log(l);
        //this.length = l; 
      });
      this.checkProgressInterval = setInterval(() => {
        this.player.getCurrentTime().then((t) => {
          this.progress = t;
        });
      }, 1000);
    },
    beforeUnmount() {
      clearInterval(this.checkProgressInterval);
    }
  };
</script>

<style scoped>
  .card {
    position: relative;
    overflow: hidden;
    color: white;
  }

  .card-body {
    z-index: 11;
  }

  p{
    margin-bottom: -10px;
    font-size: 10px3;
    font-weight: bold;
    font-style: italic;
  }

  #video-player-container {
    transform: scale(0);
    height: 0;
    width: 0;
  }

  .btn {
    background: transparent;
    border: 4px solid white;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .btn svg {
    width: 2rem;
    height: 2rem;
    fill: oklch(var(--p));
  }


  figure::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 110%;
    height: 110%;
    background: linear-gradient(180deg, #6d7325, #e2ca17);
    opacity: 0.5;
    filter: blur(5px);
  }

  /* Floating particle effects */
  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;    z-index: 100;
  }

  .particle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0px;
    height: 0px;
    box-shadow: 0 0 10px 5px #bff454;
    border-radius: 50%;    
    transform: translate(50%, 50%);
    opacity: 0;
  }

  .particle:nth-child(1)
  {
    animation: particle_move_1 20s linear infinite alternate, particle_glow 1s linear infinite alternate;
    animation-delay: 0s;
  }

  .particle:nth-child(2)
  {
    animation: particle_move_2 18s linear infinite alternate-reverse, particle_glow 1s linear infinite alternate;
    animation-delay: 2s;
  }

  .particle:nth-child(3)
  {
    animation: particle_move_1 25s linear infinite alternate, particle_glow 1s linear infinite alternate;
    animation-delay: 13s;
  }

  @keyframes particle_move_1 {
    0% {
      left: 10%;
    }
    50% {
      left: 100%;
      top: 0%;   
    }
    100% {
      left: 50%;
      top: 100%;
    }
  }

  @keyframes particle_move_2 {
    0% {
      left: 10%;
    }
    50% {
      left: 0%;
      top: 100%;   
    }
    100% {
      left: 50%;
      top: 0%;
    }
  }

  @keyframes particle_glow {
    from {
      box-shadow: 0 0 10px 5px #bff454;
      opacity: 0.5;
    }
    to {
      box-shadow: 0 0 15px 8px #bff454;
      opacity: 0.5;
    }    
  }

</style>