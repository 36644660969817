<template>
    <div>
        <div class="flex-1 relative">
            <div class="z-10">
                <h1><span class="coloredtext">44</span><span class="planet">.</span>'s zone</h1>
            </div>
            <div class="ball"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LogoComponent',
        components: {
        }
    }
</script>

<style scoped>
    /* https://medium.com/@godemode12/how-to-create-a-stunning-orbit-animation-with-css-f5226b66e85c */

    span.coloredtext {
        font-family: "Racing Sans One", sans-serif;
        font-weight: 400;
        font-style: italic;
        font-size: 50px;
        padding-right: 6px;
        margin-right: -3px;

        background: linear-gradient(130deg, #ff6f61, #ffcc00);
        /* Define your gradient colors */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    span.planet {
        --size: 60px;
        opacity: 0.6;
        z-index: 9;

        position: relative;
        font-family: sans-serif;
        font-weight: 400;
        font-style: normal;
        padding-right: 0;
        padding-top: 10px;
        font-size: 00px;
    }

    span.planet::before {
        content: 'a';
        position: absolute;
        display: inline-block;
        bottom: -1px;
        left: calc(-1 * (var(--size) * 0.66));
        top: calc(-1 * (var(--size) * 0.55));
        box-shadow: 0 0 10px 5px rgba(255, 204, 0, 0.5);
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background-image: linear-gradient(rgba(255, 133, 27, .5),
                rgba(255, 220, 0, .25),
                rgba(255, 255, 255, .5)), linear-gradient(to right,
                rgba(255, 133, 27, .5),
                rgba(255, 220, 0, .25),
                rgba(255, 255, 255, .5));
        background-color: #ddd;
    }

    h1 {
        cursor: pointer;
        font-family: "Lato", sans-serif;
        font-weight: 800;
        font-style: normal;
        font-size: 25px;
        z-index: 10;
        animation: float 20s ease-in-out infinite;
    }


    @keyframes float {
        0% {
            transform: translateY(5px)
        }

        50% {
            transform: translateY(-5px)
        }

        100% {
            transform: translateY(5px)
        }
    }

    h1:hover span.coloredtext {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        animation: shine 2s infinite;
    }

    @-webkit-keyframes shine {
        from {
            background: linear-gradient(0deg, #ff6f61, #ffcc00);
        }

        to {
            background: linear-gradient(0deg, #cb2314, #ffffff);
        }
    }

    /* Planet animation */
    .ball {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: linear-gradient(130deg, #fff1ef, #c5c3bd);
        position: absolute;
        opacity: 0.8;
        animation: ball_flyaround 10s ease-in-out infinite;
        margin: 10px;

        /* Glowy effect */
        box-shadow: 0 0 5px 5px rgba(255, 204, 0, 0.5);
    }

    @keyframes glow {
        0% {
            box-shadow: 0 0 5px 5px rgba(255, 204, 0, 0.4);
        }

        50% {
            box-shadow: 0 0 5px 6px rgba(255, 204, 0, 0.5);
        }

        100% {
            box-shadow: 0 0 5px 5px rgba(255, 204, 0, 0.4);
        }
    }

    @keyframes ball_flyaround {
        /* When planet is in front and the center of the logo */
        0% {
            top: 50%;
            left: 35%;
            transform: scale(2);
            z-index: 100;
        }

        25% {
            top: 100%;
            left: 15%;
            z-index: 0;
        }
        35%{
            transform: scale(0);
        }
        50% {
            top: 50%;
            left: 35%;
            z-index: 0;
        }
        65%{
            transform: scale(0);
        }
        75%{
            top: 0%;
            left: 65%;
            z-index: 0;
        }
        100% {
            top: 50%;
            left: 35%;
            transform: scale(2);
            z-index: 100;
        }
    }
</style>