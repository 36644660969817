<template>
    <div class="container" @click="click" :class="{right : lightMode}">
        <div class="circle" :class="{right : lightMode}">
            <span class="wave"></span> 
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"/></svg><FontAwesomeIcon :icon="['fas', 'moon']" />
            </div>
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"/></svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import {  } from '@fortawesome/fontawesome-svg-core'
</script>

<script>
/* -- -- -- -- -- -- -- -- 
A component for toggling dark mode with an smooth animation.
   -- -- -- -- -- -- -- -- */

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'DisplayModeSwitch',
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      lightMode: false
    }
  },
  setup() {
    const lightMode = localStorage.getItem('lightMode') === 'true';
    this.lightMode = lightMode;
    this.applyLightMode(lightMode);
  },
  methods: {
    click() {
        this.lightMode = !this.lightMode;
        localStorage.setItem('lightMode', this.lightMode);
        this.applyLightMode(this.lightMode);
    },
    applyLightMode(lightMode) {
      if (lightMode) {
        document.documentElement.setAttribute('data-theme', 'acid');
      } else {
        document.documentElement.removeAttribute('data-theme');
      }
    }
  }
}

</script>

<style scoped>
    .container {
        --circle-size: 30px;

        margin: 0;
        box-sizing: border-box;
        position:relative;
        width: calc(2.5 * var(--circle-size));;
        height: calc(var(--circle-size) + 7px);
        border: 1px solid #4981cf;
        border-radius: 25px;
        padding: 3px;
        background: #243f66;
        transition: all 0.3s cubic-bezier(0.83, 0, 0.17, 1); 
    }

    .container:active  {
        background: #f4e8e8;
        border-color: #d6d4b8;
    }

    .container.right {
        background: #f4e8e8;
        border-color: #d6d4b8;
    }
    .circle {
        width: var(--circle-size);
        height: var(--circle-size);
        background: #4981cf;
        border-radius: 50%;
        position: absolute;
        transition: all 0.3s cubic-bezier(0.83, 0, 0.17, 1);
        left: 3px;
        overflow: hidden;
    }
    .circle.right {
        background: #d6d4b8;
        left: calc(100% - var(--circle-size) - 3px);
    }
    .circle .icon{
        width: 60%;
        height: 60%;
        margin: 20%;
        padding: 0;
        fill: white;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
    }
    .circle .icon svg{
        animation: bounce 5s linear infinite 1s;
                width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .circle.right .icon:nth-child(2) {
        transform: translateY(100%) scale(0);
    }
    .circle  .icon:nth-child(2) {
        transform: translateY(0%) scale(1);
    }
    .circle.right  .icon:last-child {
        transform: translateY(0%) scale(1);
    }
    .circle .icon:last-child {        
        transform: translateY(-100%) scale(0);
    }

    /* Wave background */
    .circle .wave::after{
        --wave-color: rgba(30, 84, 145, 0.556);

        content: 'aaaaaaaaaaaa';
        position: absolute;
        top: -11px;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 20px;
        color:transparent;
        background: transparent;
        -moz-text-decoration-color: var(--wave-color);
        text-decoration-color: var(--wave-color);
        text-decoration-thickness: 0.1em;
        text-decoration-line: underline;
        text-decoration-style: wavy;
        transform: scale(1, 0.5) translateX(0px);
        transition: all 0.3s cubic-bezier(0.83, 0, 0.17, 1);
        animation: wave 5s linear infinite;
    }

    .circle.right .wave::after{
        --wave-color: rgba(253, 253, 253, 0.556);
        transform: translateX(-50px);
        animation-direction: reverse;
    }

    @keyframes wave {
        0% {
            transform: translateX(-1px) translateY(0px);
        }
        50% {
            transform: translateX(-30.5px) translateY(2px);
        }
        100% {
            transform: translateX(-60px) translateY(0px);
        }
    }

    @keyframes bounce {
        0% {
            transform: scale(100%);
        }
        50% {
            transform: scale(115%);
        }
        100% {
            transform: scale(100%);
        }
    }


</style>
