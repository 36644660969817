<template>
    <div>

        <div class="navbar">
            <div class="navbar-start">
              <button class="btn btn-square btn-ghost">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="inline-block h-5 w-5 stroke-current">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
            <div class="navbar-center">
                <div>
                    <LogoComponent />
                </div>
            </div>
            <div class="navbar-end px-3">
                <DisplayModeSwitch />
            </div>
          </div>
        </div>
</template>

<script>
    import DisplayModeSwitch from '../DisplayModeSwitch.vue'
    import LogoComponent from '../LogoComponent.vue'

    export default {
        name: 'HeaderComponent',
        components: {
            DisplayModeSwitch,
            LogoComponent
        }
    }
</script>

<style scoped>
span {
    font-family: "Racing Sans One", sans-serif;
    font-weight: 400;
    font-style: normal;   
}
h1 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
}
</style>